.navbar {
    top: 0;
    background-color: rgba(255, 255, 255, 0.4);
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.navcontainer {
    width: 62.5%;
    min-width: 600px;
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.navcontent {
    display: flex;
    align-items: center;
}

.logo-img {
    height: 55px;
    width: 146px;
}

a {
    text-decoration: none;
    color: inherit;
}
